import React,{useState,useEffect} from 'react'

import axios from 'axios';
import './DocumentSummary.scss';
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedIcon.svg';
import CustomDropdown from '../../Reusable/SelectDropdown/SelectDropdown';
import CountryDropdown from './CountryDropDown/CountryDropDown';
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { useParams } from 'react-router-dom';
import Alert from '../../Reusable/Alert/Alert';



const InfographSummary = () => {

 
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const [selectedWords, setSelectedKeywords] = useState([]);
    const [selectedKeyId, setSelectedKeyId] = useState([]);
    const [industries, setIndustries]= useState([]);
    const [isWordOpen, setIsKeyword] =useState(false);
    const [isLanguageOpen, setLanguageOpen] =useState(false);
    const [docLink,setDocLink] = useState('')
    const [selectedLocation, setSelectedLocation] = useState("");
    const [doctype, setDocType] =  useState('') ;
    const [docSource, setDocSource] = useState('');
    const [docTitle,setDocTitle] = useState('');
    const [description,setDescription]=useState('');
    const [selectedLang,setSelectedLang] = useState('');
    const [locationFromAPI, setLocationFromAPI] = useState([]); 
    const paramsVal = useParams();
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

  // Function to receive the selected text from CountryDropdown
  const handleLocation = (selectedValues) => {
    setSelectedLocation(selectedValues); // 
  
  };
  
    
      function handleLanguage (value){
        setSelectedLang(value);
        setLanguageOpen(false)
      }
      function toggleLanguageOpen (){
        setLanguageOpen(!isLanguageOpen);
      
      }
    function toggleTagOpen (){
        setIsKeyword(!isWordOpen)
    }
    
    function handleDocSelect (option) {
      setDocType(option);
      // sessionStorage.setItem('industry',option);
    }

    
    


    const handleError = (error) => {

      console.log("error", error);
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear(); // Clear local storage
          window.location.href = '/login'; // Redirect to login page
        } else {
          setAlertType('error');
          setAlertMessage(error.response.data.message);
          setAlertVisible(true);
        }
      } else if (error.request) {
        setAlertType('error');
        setAlertMessage("Check your connection. Unable to reach the server.");
        setAlertVisible(true);
      } else {
        setAlertType('error');
        setAlertMessage("An error occurred. Please try again later");
        setAlertVisible(true);
      }
    };
 

    function handleKeywordSelect (word) {

    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
        
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
    }
    
    if (selectedKeyId.includes(word.id)) {
        
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
        
    } else {
        
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
    
    };
    

    const getIndustry=()=>{
 
        try {
          
          const config = {
            headers: {
              "Content-Type": "application/json",
               'Authorization': `Bearer ${token}`
            },
          };
    
          axios
            .get(`${baseUrl}industry`,config)
            .then((res) => {
           
                setIndustries(res.data.data)
            
            })
            .catch((error) => {
              handleError(error)
            });
        }
        catch (error) {
          console.log("error", error);
        }
    }


     

      const docTypeOptions = [
        'Regulations',
        'Standards',
        'Guidelines',
        'Analysis',
        'Research',
        'Periodicals',
        'Others'
      ]

     

      const createInfographics = () => {
        if (
            !selectedKeyId.length || 
            !doctype || 
            !docSource || 
            !docTitle || 
            !description ||
            !selectedLocation ||
            !docLink
        ) {
            setAlertMessage("Please fill in all the fields before proceeding.")
            setAlertType("error");
            setAlertVisible(true);
          
            return;
        }
    
        const body = {
            keyId: selectedKeyId,
            type: doctype,
            source: docSource,
            title: docTitle,
            description: description,
            location: selectedLocation,
            link : docLink
        };
    
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
    
        axios.post(`${baseUrl}infographics/create`, body, config)
            .then((response) => {
                console.log(response.data);

                
                // Optionally, reset the form or navigate to another page
            })
            .catch((error) => {
              handleError(error)
            });
     };



      const getDocuments = () => {
     
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
  
            axios.get(`${baseUrl}documents/contributor/${paramsVal.docid}`, config)
                .then((response) => {
                    const data = response.data.data;
                    setDocTitle(data.title);
                    setDescription(data.description);
                    setDocSource(data.source);
                    setDocType(data.document_type);
                    setSelectedLang(data.language);
                    setLocationFromAPI(data.location || [])
                    setSelectedKeywords(data.industries.map(industry => industry.name));
                    setSelectedKeyId(data.industries.map(industry => industry.id));
                    
                })
                .catch((error) => {
                 
                    if (error.response) {
                        console.error('Error fetching document:', error.response.data);
                    } else {
                        console.error('Error fetching document:', error.message);
                    }
                });
        } catch (error) {
           
            console.error('Error fetching document:', error.message);
        }
    };
      
  
    useEffect(() => {
      if (paramsVal.docid) {
          getDocuments();
        //   const isUpdate = paramsVal.docid !== undefined;
          
      }
       // eslint-disable-next-line
    }, [paramsVal.docid]);

      useEffect(() => {
         getIndustry()
         // eslint-disable-next-line
      },[]);

  return (
    <div className='doc_summary_cont'>

    {alertVisible && 
      <Alert
          title={alertMessage}
          type={alertType}
          onClose={() => setAlertVisible(false)}
      />
    }

      <div className='doc_summary_header'>
        <h2>Infographics Summary</h2>
        <p>Fill out these details to describe your infographics.</p>
      </div>

      <form action="">
        
        <div>
            <h4>Title <span style={{color:'red'}}>*</span></h4>
            <input 
            className='doc_summary_cont_title'
            placeholder='Enter title here'
            type="text"
            value={docTitle}
            onChange={(e)=>setDocTitle(e.target.value)}
            />
        </div>
        
        <div>
           <h4>Description<span style={{color:'red'}}>*</span></h4>
           <textarea 
            placeholder='Enter text here'
            name="" 
            id=""
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
           >

           </textarea>

           <p className='doc_summary_helper'> Keep this below 150 words</p>
        </div>

     
        <div style={{position:'relative'}}>
                    <h4>Tags<span style={{color:'red'}}>*</span></h4>
                    
                    <div className='tags_drop' onClick={()=>toggleTagOpen()}>
                        {selectedWords.length > 0 ? <h4> {selectedWords.slice(0,12) + (selectedWords.length > 12 ? "   (+" + (selectedWords.length - 12) + ")" : "")}</h4>: <p>Select Tags</p>}

                        {isWordOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                             
                       
                    </div>

                    {isWordOpen && 
                      <div className='create_filter_keywd_main'>
                          <div className='create_filter_keywd'>
                              {industries.map((item,index)=>(
                                  <div className='create_filter_keywd_grid' key={index}>
                                      <div>
                                          {selectedWords.includes(item.name) ? (
                                              
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkedIcon} alt="check" />
                                          ):(
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkIcon} alt="check" />
                                          )}
                                          

                                      </div>
                                      <h5>{item.name}</h5>
                                  </div>                            
                              
                              ))}

                              
                          
                          </div>
                              <div style={{marginTop:'0px'}} className='create_filter_btn'>
                                  <button 
                                  disabled={selectedWords.length === 0}
                                  onClick={()=>setIsKeyword(false)}
                                  >
                                      Select
                                  </button>
                              </div>
                              
                      </div>
                    }
      
        </div>



          
        <div>
            <h4>Embed Link <span style={{color:'red'}}>*</span></h4>
            <input 
            className='doc_summary_cont_title'
            placeholder='Enter link to infographics'
            type="text"
            value={docLink}
            onChange={(e)=>setDocLink(e.target.value)}
            />
        </div>


        <div className='doc-summary-side'>
         
  
            <div>

                <h4>  Infographics Type<span style={{color:'red'}}>*</span></h4>
                <CustomDropdown
                  options={docTypeOptions}
                  selectedOption={doctype}
                  placeholder={"Select document type"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  onSelect={handleDocSelect}
                  marginFor={"10px"}
                />
            </div>

            <div>
                    <h4>Source<span style={{color:'red'}}>*</span></h4>
                    <input 
                     className='doc_summary_cont_source'
                    placeholder="Enter document’s source"
                    type="text"
                    value={docSource}
                    onChange={(e)=>setDocSource(e.target.value)}
                    />
            </div>
        </div>

        <div className='doc-summary-side'>
          
            <div className='dropdown_main'>
                <h4>Language <span style={{color:'red'}}>*</span></h4>

                <div className='loc_drop' onClick={()=>toggleLanguageOpen()}>
                  {selectedLang ?  <h4>{selectedLang} </h4>:
                  
                  <p>Select the appropriate language</p>}

                  {isLanguageOpen ? 
                  <IoIosArrowUp
                  color='#9CA0A5'
                  /> :
                  <IoIosArrowDown
                  color='#9CA0A5'
                  
                  />}
                  
                
                </div>
                
                  {isLanguageOpen && 
                        <div className='create_doc_filter_lang'>
                            <div onClick={()=>handleLanguage("English")}>
                                {selectedLang ==="English" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>English</h3>
                            </div>

                            <div onClick={()=>handleLanguage("French")}>
                            {selectedLang ==="French" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>French</h3>
                            </div>

                            <div onClick={()=>handleLanguage("Portuguese")}>
                            {selectedLang ==="Portuguese" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>Portuguese</h3>
                            </div>

                        </div>
                  }
            </div>

            <div>
                <h4>Geographical Coverage<span style={{color:'red'}}>*</span></h4>
                <CountryDropdown
                  placeholder={"Select location this doc covers"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  marginFor={"10px"}
                  selectedCountry={handleLocation}
                  locationFromAPI={locationFromAPI} // Pass API locations to dropdown
                />
            </div>
        </div>

       

        

        <div className='create-doc-btn-cont'>

          <button className='create-doc-btn-save'>
           Save Draft
          </button>

          <button 
          onClick={()=>createInfographics()}
          className='create-doc-btn-next'>
            Next Step
          </button>

        </div>
       

     </form>

    </div>
  )
}

export default InfographSummary
