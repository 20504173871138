           
import React,{useContext,useState} from 'react';
import arrow from '../../../assets/backicon.svg'
import { multiStepContext } from '../Reusable/StepContext/StepContext';
// import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CreateDataSideBar from './CreateDataSidebar/CreateDataSideBar';
import DataSummary from './DataSummary/DataSummary';
import CustomizeData from './CustomizeData/CustomizeData';
import CreateDataTable from './CreateTable/CreateDataTable';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:626,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  
}

const CreateData = () => {

  
  const { currentstep,setStep } = useContext(multiStepContext);
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [howDoc, setHowDoc] =  useState('');
  const [docSource, setDocSource] = useState('');
  const [docTitle,setDocTitle] = useState('');
  const [description,setDescription]=useState('');
  
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLang, setSelectedLang]= useState('');
  const location = useLocation();
  const { csvFile } = location.state || {};
  const {docData} = location.state || {};
  console.log(csvFile)
  // const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [docId, setDocId] = useState(null);
  // const [open, setOpen] = useState(false); 
  const [open] = useState(false); 
  const navigate = useNavigate();



  function handleDocData (docData) {
    setDocSource(docData.docSource)
    setDescription(docData.description)
    setDocTitle(docData.docTitle)
    setSelectedKeyId(docData.selectedKeyId)
    setHowDoc(docData.howDoc)
     setSelectedLocation(docData.selectedLocation)
     setSelectedLang(docData.selectedLang)
  }

 

  // function dateFormat(dateStr) {
  //   // Split the input string by the delimiter "-"
  //   const [day, month, year] = dateStr.split("/");
  
  //   // Return the date in the desired format
  //   return `${year}-${month}-${day}`;
  // }
    const createDocument = () => {

      

      let body ={
        "docSource": docSource,
        "description": description,
        "docTitle": docTitle,
        "selectedKeyId": selectedKeyId,
        "howDoc": howDoc,
        "selectedLocation": selectedLocation,
        "selectedLang": selectedLang,
        "file": csvFile,
        "token": token,
    
      }
      body = JSON.stringify(body)
      console.log(body)
        
   };


      const handleUpdateChange = (value, id) => {
        setIsUpdate(value);
        setDocId(id);
      };
      const handleGoback = () => {
        if (isUpdate) {
          navigate(`/view/report/pdf/${docId}`);
        } else {
          navigate("/dashboard/contributions");
        }
      };
    

  return (
    <div>

        <div className='create-doc-banner'>
           { currentstep === 1 && 
            <img 
            onClick={()=>handleGoback()}
            src={arrow} alt="" />
           }
           { currentstep === 2 && 
            <img 
            onClick={()=> setStep(1)}
            src={arrow} alt="" />
           }
        </div>

        <div className='create-doc-body'>
          {currentstep === 1 && 
          
          <DataSummary
          onSelect={handleDocData}
          onUpdateChange={handleUpdateChange}
          />
          
          }
          {currentstep === 2 && 
          <CustomizeData
           callSummaryFunction={createDocument}
           docData={docData}
           csvFile={csvFile}
          //  loadingFunction={loading}
          />}
           {currentstep === 3 && 
          <CreateDataTable
      
          />}
         
          <CreateDataSideBar
            update={isUpdate}
          />
        </div>

        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        

        </Box>
        </Modal>


    </div>
  )
}

export default CreateData
