import React from 'react'
import './CreateDocSideBar.scss';


const CreateInfoSideBar = () => {


  return (

    <div 
    
    className= 'create-doc-side-cont'>

      <div >

        <div className='create-doc-side-header'>

          <div className={`create-doc-side-num active`}>
            <h4>1</h4>
          </div>
          <div className={`create-doc-side-content active }`}>
            <h4>Ingofraphics Summary</h4>
            <p>Add some details to describe your contribution.</p>
          </div>

        </div>


      </div>

      <div className='create-doc-supp-txt'>
        <h4>Need Help?</h4>
        <p>Get to know how to prevent errors when adding a contribution.</p>

        <div>
          <h4>See Guides</h4>
        </div>
      </div>

    </div>
  )
}

export default CreateInfoSideBar
