import React, {useState,useEffect} from 'react';
import arrow from '../../../assets/backArr.png';
import './CreateNews.scss';
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import axios from 'axios';
import { useSelector } from "react-redux";
import checkIcon from '../../../assets/checkIcon.svg';
import checkedIcon from '../../../assets/checkedIcon.svg';
import { PiLinkSimpleBold } from "react-icons/pi";
import { CiCalendar } from "react-icons/ci";
import Calendar from '../Reusable/Calendar/Calendar';
import CompletePage from '../../Onboarding/Reusables/CompletePage/CompletePage';
import cupImage from '../../../assets/cup.png';
import Alert from '../Reusable/Alert/Alert';


const CreateNews = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [isLanguageOpen, setLanguageOpen] =useState(false);
  const [selectedLang,setSelectedLang] = useState('');
  const [isWordOpen, setIsKeyword] =useState(false);
  const [isDateOpen, setIsDateOpen] =useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWords, setSelectedKeywords] = useState([]);
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [industries, setIndustries]= useState([]);
  const [title,setTitle]=useState('');
  const [description,setDescription]=useState('');
  const [newsSource,setNewsSource]=useState('');
  const [newsLink,setNewsLink]=useState('');
  const [showComplete, setShowComplete] = useState(false)
  const [loadingSubmit,setLoadingSubmit] =useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  function toggleLanguageOpen (){
    setLanguageOpen(!isLanguageOpen);
  
  }
  function toggleDateOpen (){
    setIsDateOpen(!isDateOpen);
  
  }

  function handleLanguage (value){
    setSelectedLang(value);
    setLanguageOpen(false)
  }
  function toggleTagOpen (){
    setIsKeyword(!isWordOpen)
  }

  function handleKeywordSelect (word) {
    
    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
     
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
    }
    
    if (selectedKeyId.includes(word.id)) {
       
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
       
    } else {
      
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
   
  };

  function dateFormat (value){
    const [day, month, year] = value.split("/");  
    const date = new Date(year, month - 1, day);
    const isoDate = date.toISOString();
    return isoDate
  }

  const handleError = (error) => {
    
    console.log("error", error);
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear(); // Clear local storage
        window.location.href = '/login'; // Redirect to login page
      } else {
        setAlertType('error');
        setAlertMessage(error.response.data.message);
        setAlertVisible(true);
      }
    } else if (error.request) {
      setAlertType('error');
      setAlertMessage("Check your connection. Unable to reach the server.");
      setAlertVisible(true);
    } else {
      setAlertType('error');
      setAlertMessage("An error occurred. Please try again later");
      setAlertVisible(true);
    }
  };

  const handleDateSelect = (date) => {

    const day = date.getDate();
    const month = date.getMonth() + 1; // Get the month (Note: January is 0)
    const year = date.getFullYear();

    // Format day and month with leading zeros if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    setSelectedDate(formattedDate);

    setIsDateOpen(false)
    };

  const getIndustry=()=>{
 
    try {
      
      const config = {
        headers: {
          "Content-Type": "application/json",
           'Authorization': `Bearer ${token}`
        },
      };

      axios
        .get(`${baseUrl}industry`,config)
        .then((res) => {
       
            setIndustries(res.data.data)
        
        })
        .catch((error) => {
          handleError(error);
        });
    }
    catch (error) {
      console.log("error", error);
    }
    }

  const adminNewsAdd = async (e) => {
      e.preventDefault()
    if(industries.length === 0 || !selectedLang || !selectedDate){
      setAlertMessage("Please select the appropriate fields");
      setAlertType('error');
      setAlertVisible(true);
      
      return
    }

    let body ={
      
        "news": [
          {
            "headline": title,
            "industry": selectedKeyId,
            "source": newsSource,
            "language": selectedLang,
            "snippet": description,
            "google_link": newsLink,
            "date": dateFormat(selectedDate)
          }
        ]
    }

        try{
          setLoadingSubmit(true)
          const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
         }
          axios.post(`${baseUrl}news`,body,config)
          .then((res) => {
              console.log(res)
              window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling behavior
              });
              setShowComplete(true)
              setLoadingSubmit(false)
            
            }).catch(error => {
              setLoadingSubmit(false)
              handleError(error);
          })
          
        }
        catch (error) {
          console.log("error", error)}
      
          
  }
        
    
    useEffect(()=>{
        
        getIndustry();
    // eslint-disable-next-line
    },[]);

  return (
    <div >

      {alertVisible && 
                <Alert
                    title={alertMessage}
                    type={alertType}
                    onClose={() => setAlertVisible(false)}
                 />
      }

      {showComplete ? (

        <div className='create-news-cont'>
             <CompletePage
           imagePic={cupImage}
           header={"News Added"}
           subtext={""}
           btntext={"Done"}
           width={"400px"}
           height={"56px"}
           padding={"1em 1.5em"}
           link={"/dashboard/news"}
           />
        </div>
          
      ):(
     <>
      <div className='create-news-banner'>
            <img 
            onClick={()=>window.location="/dashboard/news"}
            src={arrow} alt="" />
      </div>

      <div className='create-news-form-cont'>

        <div  className='create-news-form-box'>

            <h2>Add News</h2>
            <p>Fill out these details to add news.</p>

            <form action="">

                <div>
                    <h4>Title</h4>
                    <input 
                    type="text"
                     name="" 
                     id="" 
                     required
                     value={title}
                     onChange={(e)=>setTitle(e.target.value)}
                     placeholder='Enter title here'
                     />
                </div>

                <div>
                    <h4>Description</h4>
                    <textarea 
                    name="" 
                    id=""
                    required
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                    placeholder='Enter text here...'
                    >

                    </textarea>
                    <p>Keep this below 50 words</p>
                </div>

                <div className='side_inputs'>

                    <div >
                        <h4>Embed link</h4>
                        <div className='embed_link'>
                            <input
                            style={{width:'341px',border:'none'}} 
                            type="text"
                            name="" 
                            id=""
                            value={newsLink}
                            onChange={(e)=>setNewsLink(e.target.value)}
                            required 
                            placeholder='Paste link here'
                            />

                            <PiLinkSimpleBold
                                color='#9CA0A5'
                            />
                        </div>
                        
                    </div>

                    <div className='dropdown_main'>
                      <h4>Language</h4>

                      <div className='lang_drop' onClick={()=>toggleLanguageOpen()}>
                        {selectedLang ?  <h4>{selectedLang} </h4>:
                        
                        <p>Select the appropriate language</p>}

                        {isLanguageOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                        
                       
                      </div>

                      {isLanguageOpen && 
                            <div className='create_news_filter_lang'>
                                <div onClick={()=>handleLanguage("English")}>
                                    {selectedLang ==="English" ? 
                                    <IoMdRadioButtonOn 
                                    color='#007EFF'
                                    />
                                    :
                                    <IoIosRadioButtonOff
                                
                                    color='#DDD8D0'
                                    /> }
                                    <h3>English</h3>
                                </div>

                                <div onClick={()=>handleLanguage("French")}>
                                {selectedLang ==="French" ? 
                                    <IoMdRadioButtonOn 
                                    color='#007EFF'
                                    />
                                    :
                                    <IoIosRadioButtonOff
                                
                                    color='#DDD8D0'
                                    /> }
                                    <h3>French</h3>
                                </div>

                                <div onClick={()=>handleLanguage("Portuguese")}>
                                {selectedLang ==="Portuguese" ? 
                                    <IoMdRadioButtonOn 
                                    color='#007EFF'
                                    />
                                    :
                                    <IoIosRadioButtonOff
                                
                                    color='#DDD8D0'
                                    /> }
                                    <h3>Portuguese</h3>
                                </div>

                            </div>
                      }

                    </div>
                    
                </div>

                <div style={{position:'relative'}}>
                    <h4>Tags</h4>
                    
                    <div className='tags_drop' onClick={()=>toggleTagOpen()}>
                        {selectedWords.length > 0 ? <h4> {selectedWords.slice(0,12) + (selectedWords.length > 12 ? "   (+" + (selectedWords.length - 12) + ")" : "")}</h4>: <p>Select Tags</p>}

                        {isWordOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                             
                       
                    </div>

                    {isWordOpen && 
                            <div className='create_filter_keywd_main'>
                                <div className='create_filter_keywd'>
                                    {industries.map((item,index)=>(
                                        <div className='create_filter_keywd_grid' key={index}>
                                            <div>
                                                {selectedWords.includes(item.name) ? (
                                                    
                                                    <img 
                                                    onClick={()=>handleKeywordSelect(item)}
                                                    src={checkedIcon} alt="check" />
                                                ):(
                                                    <img 
                                                    onClick={()=>handleKeywordSelect(item)}
                                                    src={checkIcon} alt="check" />
                                                )}
                                                

                                            </div>
                                            <h5>{item.name}</h5>
                                        </div>                            
                                    
                                    ))}

                                    
                                
                                </div>
                                    <div style={{marginTop:'0px'}} className='create_filter_btn'>
                                        <button 
                                        disabled={selectedWords.length === 0}
                                        onClick={()=>setIsKeyword(false)}
                                        >
                                            Select
                                        </button>
                                    </div>
                                   
                            </div>
                    }

                
                   

                    <p>Add at least 5 tags</p>
                </div>


                <div className='side_inputs'>
                    <div>
                        <h4>Source</h4>
                        <input 
                        type="text"
                        name="" 
                        style={{width:'341px'}} 
                        id=""
                        required 
                        value={newsSource}
                        onChange={(e)=>setNewsSource(e.target.value)}
                        placeholder='Enter Source'
                        />
                    </div>

                    <div style={{position:'relative'}}>
                        <h4>Date</h4>
                       
                        <div className='lang_drop' onClick={()=>toggleDateOpen()}>
                        {selectedDate ?  <h4> {selectedDate} </h4> :
                        
                        <p>Select Date</p>}

                        <CiCalendar
                        
                        />
                
                        </div>
                        
                        {isDateOpen &&  
                        <Calendar
                         onDateSelect={handleDateSelect}
                        />}
                    </div>
                       
                </div>

                <div className='create-news-btn'>
                    <button className='create-news-btn-save'>
                        Save Draft
                    </button>

                    <div>
                      {loadingSubmit ? 

                      <h4 >Please wait...</h4>
                      : 
                      <button 
                      onClick={(e)=>adminNewsAdd(e)}
                      className='create-news-btn-submit'>
                          Submit News
                      </button>
                    }
                    </div>
                </div>
               
            </form>

        </div>
      </div>
      </>
    )}

    </div>
  )
}

export default CreateNews
