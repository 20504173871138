import React,{useEffect, useState} from 'react'
import Navbar from '../Reusable/Navbar/Navbar'
import AdminSidebar from '../Reusable/AdminSidebar/AdminSidebar'
import ContributionHeader from './ContributionHeader/ContributionHeader'
import AdminResourceTable from '../Reusable/AdminResourceTable/AdminResourceTable'
import PaginationReport from '../Reusable/Pagination/ReportPagination'
import contri from '../../../assets/contributionLink.png';
import homeicon from '../../../assets/home.svg';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader'
import NoResult from '../Reusable/NoResult/NoResult'
import Alert from '../Reusable/Alert/Alert'

const AdminContributions = () => {

  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [docData,setDocData] = useState([]);
 const [loading, setLoading] = useState(true);
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage, setItemsPerPage] = useState(10);
const [totalItems, setTotalItems] = useState(0);
const [alertVisible, setAlertVisible] = useState(false);
const [alertType, setAlertType] = useState('');
const [alertMessage, setAlertMessage] = useState('');

  const [filters, setFilters] = useState({
    
    language: null,
    search:null,
    keywords: [],
    startDate: null,
    endDate: null,
    sortType: null,
    sortOrder: null
    
    });


    
  function convertDateFormat(dateString) {
  
    const [day, month, year] = dateString.split("/");
  
    return `${year}-${month}-${day}`;
  }
 

  const getDocuments = (page = 1) => {
  
    const queryParams = {
      page,
      limit: itemsPerPage,
    };
    
    
    if (filters.search) {
      queryParams.search = filters.search;
    }

    if(page){
      queryParams.page = page;
    }

    if (filters.language) {
      queryParams['language[]'] = filters.language;
    }
    if (filters.sortType) {
      queryParams.sort = filters.sortType;
    }
    if (filters.sortOrder) {
      queryParams.sort_order = filters.sortOrder;
    }
   
    if (filters.keywords.length > 0) {
      filters.keywords.forEach((item, index) => {
          queryParams[`industry[${index}]`] = item;
      });
  }
    
    if (filters.startDate) {
      queryParams.start_date = convertDateFormat(filters.startDate);
    }

    if (filters.endDate) {
      queryParams.end_date = convertDateFormat(filters.endDate);
    }

   
  const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
  try{ 

 
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/contributor?${queryString}`, config)
      .then((response) => {
      setLoading(false);
      console.log(response.data);
      setTotalItems(response.data.meta.totalItems)
       setDocData(response.data.data)
        
      
      })
      .catch((error) => {
     
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
          } else {
            setAlertMessage(error.response.data.message);
            setAlertType('error');
            setAlertVisible(true);
         
          }
        } else if (error.request) {
          setAlertMessage("Check your connection. Unable to reach the server.");
          setAlertType('error');
          setAlertVisible(true);
         
        } else {
          setAlertMessage("An error occurred while fetching data.");
          setAlertType('error');
          setAlertVisible(true);
          
        }
      });
    }
    catch(error){
      if (error.response) {
          setAlertMessage(error.response.data.message);
          setAlertType('error');
          setAlertVisible(true);

          
      } else if (error.request) {
        setAlertMessage("Check your connection. Unable to reach the server.");
        setAlertType('error');
        setAlertVisible(true);
       
      } else {
        setAlertMessage("An error occurred while fetching data.");
        setAlertType('error');
        setAlertVisible(true);
      }
    }
  };

   useEffect(()=>{   
        getDocuments(currentPage)
      // eslint-disable-next-line
      },[filters,currentPage]);


      const handleSearchChange =(search)=>{
        setFilters((prevFilters) => ({ ...prevFilters, search }));
      }

      
   const handleLanguageChange = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, language }));
   
    
  };

  const handleTagsChange = (keywords) => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords }));
   

  };

  const handleSortChange = (sortType, sortOrder) => { 
    setFilters((prevFilters) => ({ ...prevFilters, sortType, sortOrder }));
  }

  const handleDateChange = (startDate, endDate) => {

    setFilters((prevFilters) => ({ ...prevFilters, startDate, endDate }));
   
  };



  const handleLanguageRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, language: null }));
  
  };

  const handleDateRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: null,endDate:null }));
  
  };
  const handleTagsRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords:[]}));
  
  };

  return (
    <div className='dashboard-main'>
    <div>
      <AdminSidebar/>
    </div>
     
     <section>

        
       {alertVisible && 
          <Alert
            title={alertMessage}
            type={alertType}
            onClose={() => setAlertVisible(false)}
        />}
        <Navbar
        mainPage={"Home"}
        mainPageIcon={homeicon}
        page={"Contributions"}
        pageicon={contri}
        />
        <ContributionHeader
        onSearchChange={handleSearchChange}
        onLanguageChange={handleLanguageChange}
        onTagsChange={handleTagsChange}
        onDateChange={handleDateChange}
        onSortChange={handleSortChange}
        onTagsRemove={handleTagsRemove}
        onLangRemove={handleLanguageRemove}
        onDateRemove={handleDateRemove}
        />
        {loading ? 
        
        <DashboardLoader/> 
        : 
        <>
        {docData.length === 0 ? 
          <NoResult
          height="739px"
        />
        :
        <AdminResourceTable
        tableData={docData}
        />
        }
        
       
        
        <PaginationReport
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onPageChange={setCurrentPage}
        onItemsPerPageChange={setItemsPerPage}
        />
        </>
      }
     </section>
      

    </div>
  )
}

export default AdminContributions
