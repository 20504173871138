import React,{useState,useEffect} from 'react'
import './CustomizeData.scss';
import { VscEye } from "react-icons/vsc";
import Papa from 'papaparse';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import SummaryCard from '../Summary/SummaryCard';
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    overflowY:"auto",
    
  }
  
const CustomizeData = ({csvFile,docData}) => {


const [columns, setColumns] = useState([]);
const [rowCount, setRowCount] = useState(0);
const [emptyFields, setEmptyFields] = useState(0);
const [open,setOpen]=useState(false);
const [data, setData] = useState([]);
const [dataTypes, setDataTypes] = useState({});

console.log(docData);
console.log(csvFile)
console.log("ijvhis")


useEffect(() => {
    if (csvFile) {
        Papa.parse(csvFile, {
            header: true,
            complete: (results) => {
                const data = results.data;
                const headers = results.meta.fields;
                setColumns(headers);
                setData(data);
                setRowCount(data.length);
                setEmptyFields(data.reduce((acc, row) => {
                    return acc + Object.values(row).filter(value => !value).length;
                }, 0));
                setDataTypes(headers.reduce((acc, header) => {
                    acc[header] = 'string'; // Default data type
                    return acc;
                }, {}));
            }
        });
    }
}, [csvFile]);

const handleDataTypeChange = (e, column) => {
    setDataTypes({
        ...dataTypes,
        [column]: e.target.value
    });
};


  return (
    
    
      <div className='doc_summary_cont'>

        <div className='doc_summary_header'>
            <h2>Customize Data</h2>
            <p>Populate and validate your data</p>
        </div>
        

        <div className='data_stats'>
            <p>Here’s what we found in your data</p>

            <ul>
                    <li>Column count: {columns.length}</li>
                    <li>Row count: {rowCount}</li>
                    <li>Empty fields: {emptyFields}</li>
            </ul>
        </div>

        <div className='data_columns'>

            <div className='data_prev_btn'>
              <p>Match the headers to the column data types below.</p>

              <div
              onClick={()=>setOpen(true)}
              >
                <VscEye 
                color='#007EFF'
                size={20}
                />
                <h4>Preview Data</h4>
              </div>

            </div>

            <div className='data_columns_preview'>
                    {columns.map((column, index) => (
                        <div key={index}>
                            <h4>{column}</h4>
                            <select
                                 style={{width: '100%'}}
                                className='data_columns_preview_select'
                                value={dataTypes[column]}
                                onChange={(e) => handleDataTypeChange(e, column)}
                            >
                                  <option value="string">Single line of text</option>
                                <option value="multiplw">Multiple lines of text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="bool">True/False</option>
                                <option value="null">DO NOT IMPORT</option>
                                {/* Add more options as needed */}
                            </select>
                        </div>
                    ))}
            </div>
        </div>

        <div className='create-doc-btn-cont'>

        <button className='create-doc-btn-save'>
        Save Draft
        </button>

        <button 
        // onClick={(e)=>goToConfirmPage(e)}
        className='create-doc-btn-next'>
        Submit
        </button>

        </div>

         
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >
        <div>
        <div className='pdf-main-cont'>

                <div className='data-modal-prev-header'>

                    <div>
                        <h1>Preview Contribution</h1>
                    </div>

                   
                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>setOpen(false)}
                            className='pdf-header-close-btn'
                            >Close Preview</h4>
                        </div>

                    
                     
                        <BaseButton
                        
                            title='Submit'
                            height='44px'
                            width='75px'
                            padding="0em .5em"
                        />
                              
                    </div>

                </div>


                <section>
                    <SummaryCard
                    docInfo={docData}
                    />
                </section>

                <section className='import-csv-previev-cont' >

                <table
                    style={{width: '100%', borderCollapse: 'collapse'}} 
                >

                
                    <thead>
                        <tr style={{textAlign: 'left', backgroundColor:"#F6F6F6"}}>
                            {columns.map((column, index) => (
                                <th className='preview-column-txt same-width' key={index}>{column}</th>
                            ))}
                        </tr>
                        <tr style={{textAlign: 'left'}}>
                            {columns.map((column, index) => (
                                <th key={index} className='same-width'>
                                    <select
                                        value={dataTypes[column]}
                                        onChange={(e) => handleDataTypeChange(e, column)}
                                        className='data_columns_preview_select'
                                    >
                                        <option value="string">Single line of text</option>
                                        <option value="multiplw">Multiple lines of text</option>
                                        <option value="number">Number</option>
                                        <option value="date">Date</option>
                                        <option value="bool">True/False</option>
                                        <option value="null">DO NOT IMPORT</option>
                                        {/* Add more options as needed */}
                                    </select>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr style={{borderBottom:"1px solid #F6F6F6"}} key={rowIndex}>
                                {columns.map((column, colIndex) => (
                                    <td className='data_columns_preview_rows same-width' key={colIndex}>{row[column]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                    </table>

                </section>

                

                    
            </div>
     
        </div>

        </Box>
        </Modal>


    </div>
  )
}

export default CustomizeData
