import React, {useState}from 'react';
import './DocAcknowledge.scss';
import toggleA from '../../../../assets/toggleActive.png';
import toggleIn from '../../../../assets/toggleInactive.png';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import ReportCard from '../../Reusable/ReportCard/ReportCard';
import Alert from '../../Reusable/Alert/Alert';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 560,
  height: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  // overflowY:"auto"
 
};

const DocAcknowledge = ({callSummaryFunction,loadingFunction}) => {

const [ack1, setAck1] = useState(false);
const [ack2, setAck2] = useState(false);
const [ack3, setAck3] = useState(false);
const [open,setOpen] = useState(false);
const [alertVisible, setAlertVisible] = useState(false);
const [alertType, setAlertType] = useState('');
const [alertMessage, setAlertMessage] = useState('');

function submitReport (){
  if (
    !ack1 ||
    !ack2 ||
    !ack3 
  ) {
    setAlertMessage("Please toggle all butons before proceeding");
    setAlertType("error");
    setAlertVisible(true);
    
    return; 
  }
  else{
    callSummaryFunction()
  }
  
}

  return (

    <div className='doc-acknowledge-cont'>

    {alertVisible && 
        <Alert
            title={alertMessage}
            type={alertType}
            onClose={() => setAlertVisible(false)}
        />
    }
      
      <div className='doc-acknowledge-header'>
        <h2>Submit for Review</h2>
        <p>Send your contribution for review</p>
      </div>

      <h4>Acknowlegdement</h4>

      <div style={{marginBottom:"60px"}}>

        <div className='doc-ack-content'>
          {ack1 ? 
            <div>
            <img 
              onClick ={()=>setAck1(false)}
              src={toggleA} alt="icon" />
          </div>
          
          :
          <div>
            <img
              onClick ={()=>setAck1(true)}
              src={toggleIn} alt="icon" />
          </div>
       
          }
            <p>I acknowledge that I possess the requisite rights and permissions to upload this report.</p>
        </div>
       
        <div className='doc-ack-content'>
        {ack2 ? 
             <div>
             <img 
               onClick ={()=>setAck2(false)}
               src={toggleA} alt="icon" />
         </div>
         
         :
         <div>
            <img
             onClick ={()=>setAck2(true)}
             src={toggleIn} alt="icon" />
         </div>
        
          }
            <p>I indemnify Sonaroid of any liability that may arise as a result of my submission of this report.</p>
        </div>

        <div className='doc-ack-content'>
        {ack3 ? 
              <div>
                  <img 
                    onClick ={()=>setAck3(false)}
                    src={toggleA} alt="icon" />
              </div>
              
              :
              <div>
                 <img
                  onClick ={()=>setAck3(true)}
                  src={toggleIn} alt="icon" />
              </div>
             
          }
            <p>I have read and I agree with Sonaroid's <span><a  target='_blank' rel="noreferrer" href="https://sonaroid.io/privacypolicy">Privacy Policy</a></span> and <span><a target='_blank' rel="noreferrer" href="https://sonaroid.io/termsofservice">Terms of Service.</a></span> </p>
        </div>
       
      </div>

      <div className='create-doc-btn-cont'>

          <button className='create-doc-btn-save'>
          Save Draft
          </button>

          { loadingFunction ?
          <div style={{textAlign:"center"}}>
            <h4 style={{fontWeight:'400'}}>Please wait...</h4>
          </div>
          
          :

          <button 
          onClick={()=>submitReport()}
          className='create-doc-btn-next'>
            Confirm Submission
          </button>
        }

      </div>

      <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >

                <div style={{textAlign:"right"}}>
                    <img 
                    onClick={()=>setOpen(false)}
                    src={closeIcon} alt="" />
                </div>
                

                <div className='cont_modal_header'>

                    <h2>Report Sample</h2>
                    <p>Your contribution has been submitted and is under review.</p>

                </div>

               

                <div className='report-temp-cont'>
                    <div>
                      <ReportCard
                      contHeight={"327px"}
                      contWidth={"220px"}
                      imgBoxWidth={"80px"}
                      contPadding={"1em"}
                      transformLine={"rotate(137deg)"}
                      lineHeight={"0.6cm"}
                      lineRight={"44px"}
                      lineTop={"53px"}
                      lineWidth={"70px"}
                      transformOrigin={"top right"}
                      headerFont={"1rem"}
                      lineBottom={"-30px"}
                      lineLeft={"-62px"}
                      />
                    </div>
                </div>

                <button 
               
                className='cont_modal_button'>
                    Close
                </button>
              </Box>
        </Modal>

    </div>
  )
}

export default DocAcknowledge
