import React, { useState } from 'react'
import emailIcon from '../../../assets/emailIcon.svg';
import imgone from '../../../assets/iconpadlock.svg';
import imgtwo from '../../../assets/iconeyepasswor.svg'
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import OnboardingSidebar from '../../Onboarding/Reusables/OnboardingSidebar/OnboardingSidebar';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { USER_LOGIN_SUCCESS } from '../../../redux/userConstants';
import axios from "axios";
import './Login.scss';
import Alert from '../../AdminDashboard/Reusable/Alert/Alert';

const Login = () => {

  
  const [email,setEmail]= useState('');
  const [password,setPassword]= useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
 
  const handleSubmit = (e) => {
    e.preventDefault()
    let body={  
      
        "email": email,
        "password": password 
    }
    
  
       
      try {
        setLoading(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        axios
          .post(`${baseUrl}auth/signin`,body,config)
          .then((res) => {
              console.log(res)
              sessionStorage.clear()
              const { user, token } = res.data.data;
              
              dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: { user, token },  
              });   
              if(res.data.status === "Success"){
                localStorage.setItem('userInfo', JSON.stringify({ user, token }));
              }
             
              const { is_verified, account_type } = user; 
             
          
                 if ((is_verified) && (account_type === "super_admin")){
                  navigate('/overview/home');
                }
                else{
                  setAlertType('error');
                  setAlertMessage("Unauthorized");
                  setAlertVisible(true);
                  setLoading(false)
                  return
                }
            
             
              // const { is_verified, user_status } = user; 

              //   const ROUTES = {
              //     VERIFY_EMAIL: "/verifymail",
              //     GET_STARTED: "/getstarted/flow-1",
              //   };

                // if (!is_verified) {
                //   navigate(ROUTES.VERIFY_EMAIL);
                // } else if (is_verified && user_status === "onboarding") {
                //   navigate(ROUTES.GET_STARTED);
                // }
            
              setLoading(false)
          })
          .catch((error) => {
         
            setLoading(false)
            console.log(error)
            if (error.response) {
              setAlertType(error.response.data.message);
              setAlertMessage("An error occurred. Please try again later");
              setAlertVisible(true);
             
            } else if (error.request) {
              setAlertType('error');
              setAlertMessage("Check your connection. Unable to reach the server.");
              setAlertVisible(true);
              
            } else {
              setAlertType('error');
              setAlertMessage("An error occurred. Please try again later");
              setAlertVisible(true);
              
            }
          });
      }
      catch (error) {
        console.log("error", error);
      }
    
     
  };

  return (

    <div className='onboard_cont'>
       {alertVisible && 
                <Alert
                    title={alertMessage}
                    type={alertType}
                    onClose={() => setAlertVisible(false)}
                 />
            }
        <div>
            <OnboardingSidebar/>
        </div>

        <div className='login_page_cont'>

            <div className='login_header'>
                <h3>Login</h3>
              
            </div>
           

            <form
              onSubmit={handleSubmit}
            >
            <div className='signup_email'>
                <h4> Enter your email</h4>
                <div>
                <img src={emailIcon} alt="icon" />
                <input 
                required
                placeholder='e.g. name@example.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email" 
                />
                </div>
                
            </div>
            <div className='signup_pass'>
                <h4>Enter password</h4>
                <div>
                <img src={imgone} alt="icon" />
                <input
                required
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
                <img 
                 onClick={()=>togglePasswordVisibility()} 
                 style={{ cursor: 'pointer' }}
                src={imgtwo} alt="icon" />
                </div>
                
            </div>

           

            {loading ?(
            <p style={{textAlign:'center'}}>Please wait...</p>
            ):(
        
            <BaseButton
                title={"Login"}
                width={"380px"}
                height={"44px"}
                padding={"0.5em 1em"}
            />)}
            </form>
        </div>
      
    </div>
  )
}

export default Login
