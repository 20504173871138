import React from "react";
import styles from "./SummaryCard.module.css";

const SummaryCard = ({docInfo}) => {
  return (
    <div className={styles.card}>

      <h3 className={styles.title}>Summary</h3>

      <div className={styles.sum_content}>

      <div className={styles.row}>
        <strong>Title</strong>
        <span>{docInfo.docTitle}</span>
      </div>

      <div className={styles.row}>
        <strong>Description</strong>
        <p>
          {docInfo.description}
        </p>
      </div>

      <div className={styles.row}>
        <strong>Tags</strong>
        <div className={styles.tags}>
        {docInfo.selectedWords.slice(0, 5).map((info, index) => (
            <span key={index} className={styles.tag}>{info}</span>
        ))}

        
        {docInfo.selectedWords.length > 5 && (
            <span className={styles.tag}>+ {docInfo.length - 5} more</span>
        )}
     </div>
      </div>

      <div className={styles.row}>
        <strong>Scope</strong>
        <span>{docInfo.scope}</span>
      </div>



      <div className={styles.row}>
        <strong>Coverage</strong>
        <span>{docInfo.selectedLocation.countries}</span>
      </div>

      <div className={styles.row}>
        <strong>Source</strong>
        <span>{docInfo.docSource}</span>
      </div>

      </div>
    </div>
  );
};

export default SummaryCard;
