import React from 'react';
import '../CreateDocument/CreateDocument.scss';
import arrow from '../../../assets/backicon.svg'
import InfographSummary from './DocumentSummary/InfographSummary';
import CreateInfoSideBar from './CreateInfoSidebar/CreateInfoSideBar';
import { useNavigate } from 'react-router-dom';
const CreateInfograph = () => {

  const navigate = useNavigate();

  return (
    <div>

        <div className='create-doc-banner'>
          
            <img 
            onClick={()=> navigate("/dashboard/contributions")}
            src={arrow} alt="" />
           
         
        </div>

        <div className='create-doc-body'>
       
          
          <InfographSummary /> 
          <CreateInfoSideBar />
        </div>

    </div>
  )
}

export default CreateInfograph
