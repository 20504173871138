import React,{useContext} from 'react'
import './CreateDataSideBar.scss';
import { multiStepContext } from '../../Reusable/StepContext/StepContext';

const CreateDataSideBar = ({update}) => {

   const { currentstep } = useContext(multiStepContext);

  return (

    <div 
    
    className={currentstep === 1 ? 'create-doc-side-cont':'create-doc-side-cont alt'}>

      <div >

        <div className='create-doc-side-header'>

          <div className={`create-doc-side-num ${currentstep >= 1 ? 'active' : ''}`}>
            <h4>1</h4>
          </div>
          <div className={`create-doc-side-content ${currentstep >= 1 ? 'active' : ''}`}>
            <h4>Data Summary</h4>
            <p>Add some details to describe your contribution.</p>
          </div>

        </div>

        {!update && 
        <div className='create-doc-side-header'>

          <div className={`create-doc-side-num ${currentstep > 1 ? 'active' : ''}`}>
            <h4>2</h4>
          </div>
          <div className={`create-doc-side-content ${currentstep > 1 ? 'active' : ''}`}>
            <h4>Customize Data</h4>
            <p>Populate and validate your data</p>
          </div>

        </div>
        }

      </div>

      <div className='create-doc-supp-txt'>
        <h4>Need Help?</h4>
        <p>Get to know how to prevent errors when adding a contribution.</p>

        <div>
          <h4>See Guides</h4>
        </div>
      </div>

    </div>
  )
}

export default CreateDataSideBar
