import React,{useState,useContext,useEffect} from 'react'
import upload from '../../../../assets/file upload states.svg';
import axios from 'axios';
import './DocumentSummary.scss';
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedIcon.svg';
import uploaded from '../../../../assets/uploadsucess.svg';
import bin from '../../../../assets/bin.svg'
import { CiCalendar } from "react-icons/ci";
import CustomDropdown from '../../Reusable/SelectDropdown/SelectDropdown';
import { multiStepContext } from '../../Reusable/StepContext/StepContext';
import CountryDropdown from './CountryDropDown/CountryDropDown';
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { useParams } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';
import ReportCard from '../../Reusable/ReportCard/ReportCard';
import Alert from '../../Reusable/Alert/Alert';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:626,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  
}

const DocumentSummary = ({ onSelect,onUpdateChange }) => {

  const {setStep} = useContext(multiStepContext);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [selectedWords, setSelectedKeywords] = useState([]);
    const [selectedKeyId, setSelectedKeyId] = useState([]);
    const [industries, setIndustries]= useState([]);
    const [isWordOpen, setIsKeyword] =useState(false);
    const [isLanguageOpen, setLanguageOpen] =useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [doctype, setDocType] =  useState('') ;
    const [howDoc, setHowDoc] =  useState('');
    const [docSource, setDocSource] = useState('');
    const [docTitle,setDocTitle] = useState('');
    const [description,setDescription]=useState('');
    const [author,setAuthor]= useState('');
    const [selectedLang,setSelectedLang] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(""); // Track the selected text
    const [locationFromAPI, setLocationFromAPI] = useState([]); 
    const [open, setOpen] = useState(false); 
    const paramsVal = useParams();
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');


  // Function to receive the selected text from CountryDropdown
  const handleLocation = (selectedValues) => {
    setSelectedLocation(selectedValues); // 
  
  };

    const docData = {
      file,
      docSource,
      doctype,
      author,
      selectedKeyId,
      selectedDate,
      howDoc,
      docTitle,
      description,
      selectedLocation,
      selectedLang
    }

    
    
    function handleLanguage (value){
      setSelectedLang(value);
      setLanguageOpen(false)
    }

    function toggleLanguageOpen (){
        setLanguageOpen(!isLanguageOpen); 
    }
    
    function toggleTagOpen (){
        setIsKeyword(!isWordOpen)
    }
    
    function handleDocSelect (option) {
      setDocType(option);
      // sessionStorage.setItem('industry',option);
    }

    

    function handleDocHow (option) {
      setHowDoc(option);
      // sessionStorage.setItem('industry',option);
    }
    
  // const handleDateSelect = (date) => {

  //   const day = date.getDate();
  //   const month = date.getMonth() + 1; // Get the month (Note: January is 0)
  //   const year = date.getFullYear();

  //   // Format day and month with leading zeros if needed
  //   const formattedDay = day < 10 ? '0' + day : day;
  //   const formattedMonth = month < 10 ? '0' + month : month;

  //   const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
  //   setSelectedDate(formattedDate);

  //   setIsDateOpen(false)
  //   };

  const handleError = (error) => {

    console.log("error", error);
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear(); // Clear local storage
        window.location.href = '/login'; // Redirect to login page
      } else {
        setAlertType('error');
        setAlertMessage(error.response.data.message);
        setAlertVisible(true);
      }
    } else if (error.request) {
      setAlertType('error');
      setAlertMessage("Check your connection. Unable to reach the server.");
      setAlertVisible(true);
    } else {
      setAlertType('error');
      setAlertMessage("An error occurred. Please try again later");
      setAlertVisible(true);
    }
  };


  const handleYearChange = (e) => {
    const currentYear = new Date().getFullYear();
    const year = e.target.value;
    if (year <= currentYear || year < 1900 ) {
       
        setSelectedDate(year);
    } else {
        setAlertMessage(`Year must be between 1900 and ${currentYear}`)
        setAlertType("error");
        setAlertVisible(true);
    }
  };

    function handleKeywordSelect (word) {

    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
        
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
    }
    
    if (selectedKeyId.includes(word.id)) {
        
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
        
    } else {
        
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
    
    };
    

    const getIndustry=()=>{
 
        try {
          
          const config = {
            headers: {
              "Content-Type": "application/json",
               'Authorization': `Bearer ${token}`
            },
          };
    
          axios
            .get(`${baseUrl}industry`,config)
            .then((res) => {
           
                setIndustries(res.data.data)
            
            })
            .catch((error) => {
              handleError(error)
            });
        }
        catch (error) {
          console.log("error", error);
        }
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        handleFileUpload(selectedFile);
      };
    
    
    
      const handleFileUpload = (file) => {
        if (file && file.type === 'application/pdf') {
          if (file.size <= 10 * 1024 * 1024) { // 10MB in bytes
            setFile(file);
            setError('');
          } else {
            setError('File size exceeds 4MB');
          }
        } else {
          setError('Please upload a valid PDF file');
        }
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        handleFileUpload(droppedFile);
      };
    

      const docTypeOptions = [
        'Regulations',
        'Standards',
        'Guidelines',
        'Analysis',
        'Research',
        'Periodicals',
        'Others'
      ]

      const sourceOptions = [
        'Social Media',
        'Company Report',
        'I work there',
        'I would rather not  say',
      ]

      function goToConfirmPage(e) {
        e.preventDefault()
        const docId = paramsVal.docid;
           
      
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header
          },
        };
      
        if (docId) {

          let body = {
            title: docTitle,
            description,
            author,
            source: docSource,
            access: howDoc,
            document_type: doctype,
            language: selectedLang,
            industry: selectedKeyId,
            location: selectedLocation.countries,
            region: selectedLocation.regions.length === 5 ? 'Africa' : selectedLocation.regions.length < 5 || selectedLocation.countries.length > 1 ? 'multiple' : selectedLocation.regions.length === 0 && selectedLocation.countries.length === 1 ? selectedLocation.countries[0] : selectedLocation.regions
          };
  
          if (
            !file || 
            !selectedKeyId.length === 0|| 
            !selectedDate || 
            !doctype ||  
            !docSource || 
            !docTitle || 
            !description ||
            !selectedLocation 
          ) {
            
            setAlertMessage("Please fill in all the fields before proceeding.")
            setAlertType("error");
            setAlertVisible(true);
            return; 
          }
          axios.patch(`${baseUrl}documents/contributor/${docId}`, body, config)
            .then((response) => {
              console.log(response)
              setOpen(true);
            
            })
            .catch((error) => {
              handleError(error)
            });
        } else {
          if (
            !file || 
            !selectedKeyId.length === 0|| 
            !selectedDate || 
            !doctype ||  
            !docSource || 
            !docTitle || 
            !description ||
            !selectedLocation 
          ) {
            
            setAlertMessage("Please fill in all the fields before proceeding.")
            setAlertType("error");
            setAlertVisible(true);
            return; 
          }
  
          onSelect(docData);
          setStep(2);
          window.scrollTo(0, 0);
        }
      }



      const getDocuments = () => {
     
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
  
            axios.get(`${baseUrl}documents/contributor/${paramsVal.docid}`, config)
                .then((response) => {
                    const data = response.data.data;
                    setDocTitle(data.title);
                    setDescription(data.description);
                    setAuthor(data.created_by_first_name + ' ' + data.created_by_last_name);
                    setDocSource(data.source);
                    setDocType(data.document_type);
                    setSelectedLang(data.language);
                    setSelectedDate(data.publication_year);
                    setLocationFromAPI(data.location || [])
                   
                    setHowDoc(data.access);
                    setSelectedKeywords(data.industries.map(industry => industry.name));
                    setSelectedKeyId(data.industries.map(industry => industry.id));
                    setFile(data.url);
                })
                .catch((error) => {
                 
                    if (error.response) {
                        console.error('Error fetching document:', error.response.data);
                    } else {
                        console.error('Error fetching document:', error.message);
                    }
                });
        } catch (error) {
           
            console.error('Error fetching document:', error.message);
        }
    };
      
  
    useEffect(() => {
      if (paramsVal.docid) {
          getDocuments();
          const isUpdate = paramsVal.docid !== undefined;
          onUpdateChange(isUpdate, paramsVal.docid);
      }
       // eslint-disable-next-line
    }, [paramsVal.docid,onUpdateChange]);

      useEffect(() => {
         getIndustry()
         // eslint-disable-next-line
      },[]);

  return (
    <div className='doc_summary_cont'>

      {alertVisible && 
          <Alert
              title={alertMessage}
              type={alertType}
              onClose={() => setAlertVisible(false)}
          />
      }

      <div className='doc_summary_header'>
        <h2>Document Summary</h2>
        <p>Fill out these details to describe your report.</p>
      </div>

      <form action="">
        
        <div>
            <h4>Title of document <span style={{color:'red'}}>*</span></h4>
            <input 
            className='doc_summary_cont_title'
            placeholder='Enter title here'
            type="text"
            value={docTitle}
            onChange={(e)=>setDocTitle(e.target.value)}
            />
        </div>
        
        <div>
           <h4>Description<span style={{color:'red'}}>*</span></h4>
           <textarea 
            placeholder='Enter text here'
            name="" 
            id=""
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
           >

           </textarea>

           <p className='doc_summary_helper'> Keep this below 150 words</p>
        </div>

     
        <div style={{position:'relative'}}>
                    <h4>Tags<span style={{color:'red'}}>*</span></h4>
                    
                    <div className='tags_drop' onClick={()=>toggleTagOpen()}>
                        {selectedWords.length > 0 ? <h4> {selectedWords.slice(0,12) + (selectedWords.length > 12 ? "   (+" + (selectedWords.length - 12) + ")" : "")}</h4>: <p>Select Tags</p>}

                        {isWordOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                             
                       
                    </div>

                    {isWordOpen && 
                      <div className='create_filter_keywd_main'>
                          <div className='create_filter_keywd'>
                              {industries.map((item,index)=>(
                                  <div className='create_filter_keywd_grid' key={index}>
                                      <div>
                                          {selectedWords.includes(item.name) ? (
                                              
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkedIcon} alt="check" />
                                          ):(
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkIcon} alt="check" />
                                          )}
                                          

                                      </div>
                                      <h5>{item.name}</h5>
                                  </div>                            
                              
                              ))}

                              
                          
                          </div>
                              <div style={{marginTop:'0px'}} className='create_filter_btn'>
                                  <button 
                                  disabled={selectedWords.length === 0}
                                  onClick={()=>setIsKeyword(false)}
                                  >
                                      Select
                                  </button>
                              </div>
                              
                      </div>
                    }
      
        </div>

        <div >
              <h4>Author</h4>
              <input 
                className='doc_summary_cont_author'
                placeholder="Enter author's name"
                type="text" 
                value={author}
                onChange={(e)=>setAuthor(e.target.value)}
              />
        </div>

        <div className='doc-summary-side'>
         

          <div>
                  <h4>Publication Year<span style={{color:'red'}}>*</span></h4>

                  <div>
                   
                  </div>

                  <div className='date_drop'>
                          

                    <CiCalendar
                    size={20}
                    />
                    <input
                        type="number"
                        value={selectedDate}
                        onChange={handleYearChange}
                        placeholder="YYYY"
                    />
                    {/* {selectedDate ?  <h4> {selectedDate} </h4> :
                    
                    <p>DD/MM/YYYY</p>}
            
                    </div>
                    
                    {isDateOpen &&  
                    <Calendar
                    calWidth={"260px"}
                    onDateSelect={handleDateSelect}
                    />
                    } */}
          </div>
        </div>

          <div>
                <h4>Document Type<span style={{color:'red'}}>*</span></h4>
                <CustomDropdown
                  options={docTypeOptions}
                  selectedOption={doctype}
                  placeholder={"Select document type"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  onSelect={handleDocSelect}
                  marginFor={"10px"}
                />
            </div>
        </div>

        <div className='doc-summary-side'>
          
            <div className='dropdown_main'>
                <h4>Language <span style={{color:'red'}}>*</span></h4>

                <div className='loc_drop' onClick={()=>toggleLanguageOpen()}>
                  {selectedLang ?  <h4>{selectedLang} </h4>:
                  
                  <p>Select the appropriate language</p>}

                  {isLanguageOpen ? 
                  <IoIosArrowUp
                  color='#9CA0A5'
                  /> :
                  <IoIosArrowDown
                  color='#9CA0A5'
                  
                  />}
                  
                
                </div>
                
                  {isLanguageOpen && 
                        <div className='create_doc_filter_lang'>
                            <div onClick={()=>handleLanguage("English")}>
                                {selectedLang ==="English" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>English</h3>
                            </div>

                            <div onClick={()=>handleLanguage("French")}>
                            {selectedLang ==="French" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>French</h3>
                            </div>

                            <div onClick={()=>handleLanguage("Portuguese")}>
                            {selectedLang ==="Portuguese" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>Portuguese</h3>
                            </div>

                        </div>
                  }
            </div>

            <div>
                <h4>Geographical Coverage<span style={{color:'red'}}>*</span></h4>
                <CountryDropdown
                  placeholder={"Select location this doc covers"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  marginFor={"10px"}
                  selectedCountry={handleLocation}
                  locationFromAPI={locationFromAPI} // Pass API locations to dropdown
                />
            </div>
        </div>

        <div className='doc-summary-side'>
            <div>
                    <h4>Source<span style={{color:'red'}}>*</span></h4>
                    <input 
                     className='doc_summary_cont_source'
                    placeholder="Enter document’s source"
                    type="text"
                    value={docSource}
                    onChange={(e)=>setDocSource(e.target.value)}
                    />
            </div>

            <div>
                <h4>How did you get this document? </h4>

                <div>
                  <CustomDropdown
                    options={sourceOptions}
                    selectedOption={howDoc}
                    placeholder={"How did you get this document?"}
                     heightFor="42px"
                    paddingFor="0.5em"
                    onSelect={handleDocHow}
                    marginFor={"10px"}
                    
                  />
                </div>
            </div>
        </div>
          

        {paramsVal.docid ? 
        <p className='edit_doc_help'> Please note that you cannot change the previously uploaded document. To change the document, kindly withdraw this contribution and create a new one.</p>
        :
        <div>
            <h4>Attach the PDF file containing your report.<span style={{color:'red'}}>*</span></h4>

                   {file ? (
                        <>
                             <div className="more_about_one_pp">
                                <div>
                        
                                    <div>
                                        <img src={uploaded} alt="Upload Icon" />
                                    </div>

                                    <p className="click_drag_uploaded">
                                     Upload Successful
                                    </p>
                                                                   
                                    {file && <p className="click_svg">{file.name}</p>}
                                    <div 
                                     onClick={()=>setFile(null)}
                                    className='clear_upload'>
                                    <img 
                                   
                                    src={bin} alt="" />
                                    <h4 style={{margin:'0'}}>Clear upload</h4>
                                </div>
                                </div>

                                
                             
                            </div>
                            
                            

                        </>
                           
                        ):(
                        <>
            
                <div 
                    className="more_about_one_pp"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    <div>
                    
                        <div>
                            <img src={upload} alt="Upload Icon" />
                            </div>

                                <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                accept=".pdf"
                                onChange={handleFileChange}
                                />

                                <p className="click_drag_upload">
                                Click to upload <span>or drag and drop</span>
                                </p>
                                <p className="click_svg">PDF (max. 10 megabytes)</p>

                                {error && <p className="error-message">{error}</p>}
                              
                        </div>
                    </div>
                    </>)}
                    
        </div>
        }

        <div className='create-doc-btn-cont'>

          <button className='create-doc-btn-save'>
           Save Draft
          </button>

          <button 
          onClick={(e)=>goToConfirmPage(e)}
          className='create-doc-btn-next'>
            Next Step
          </button>

        </div>
       

     </form>

     <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-reject-modal' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                  
                    <h4 style={{margin:10}}>Done!</h4>
                    <p style={{textAlign:'center',color:'#4B4F57',fontSize:"1rem"}}> Your contribution has been submitted and is under review.</p>
                    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'1em' }}>
                    <ReportCard
                         contHeight={"360px"}
                         contWidth={"254px"}
                         imgBoxWidth={"100px"}
                         contPadding={"1em"}
                         transformLine={"rotate(40deg)"}
                         lineHeight={"1.6cm"}
                         lineRight={"-5px"}
                         lineTop={"0px"}
                         lineWidth={"20px"}
                         transformOrigin={"top right"}
                         headerFont={"1rem"}
                         lineBottom={"-20px"}
                         lineLeft={"25px"}
                         textMarginTop={"10px"}
                         docText={docTitle}
                         docYear={selectedDate}
                         yearFont={"0.8rem"}
                />
                    </div>
                    
                </div>

                

                <div>
                    <BaseButton
                        title={"Close"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={() =>  window.location = '/dashboard/contributions'}
                    />
                </div>
            </div>
        </div>

        </Box>
        </Modal>


    </div>
  )
}

export default DocumentSummary
