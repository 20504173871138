import React, { useContext, useState } from 'react';
import './CreateDataTable.scss';
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import closeIcon from '../../../../assets/greys.png';
import { multiStepContext } from '../../Reusable/StepContext/StepContext';
import Alert from '../../Reusable/Alert/Alert';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding: "16px 32px 32px",
  overflowY: "auto",
};

const smallModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 512,
  height:427,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  outline: "none",
  padding: "24px",
};

const CreateDataTable = () => {
  const [open, setOpen] = useState(false);
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState('');
  const [newColumnType, setNewColumnType] = useState('string');
  const { setStep } = useContext(multiStepContext);
  const [dataTypes, setDataTypes] = useState({});
  const [columns, setColumns] = useState([]); 
  const [data, setData] = useState([{}]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const handleNewRow = () => {
    setData([...data, {}]);
  };

  const handleNewColumn = () => {
    setSmallModalOpen(true);
  };

  // const handleSaveNewColumn = () => {
  //   setColumns([...columns, newColumnName]);
  //   setDataTypes({ ...dataTypes, [newColumnName]: newColumnType });
  //   setSmallModalOpen(false);
  //   setNewColumnName('');
  //   setNewColumnType('string');
  // };


  const handleSaveNewColumn = () => {
    if (columns.includes(newColumnName)) {
      setAlertVisible(true);
      setAlertType('error');
      setAlertMessage('Column name already exists.');
      
      return;
    }
    setColumns([...columns, newColumnName]);
    setDataTypes({ ...dataTypes, [newColumnName]: newColumnType });
    setSmallModalOpen(false);
    setNewColumnName('');
    setNewColumnType('string');
    setAlertVisible(false);
    
  };

  const handleDataTypeChange = (e, column) => {
    setDataTypes({ ...dataTypes, [column]: e.target.value });
  };

  // ...existing code...

const validateValue = (value, dataType) => {
  switch (dataType) {
    case 'string':
    case 'text':
      return true; // No specific validation for text
    case 'number':
      return !isNaN(value);
    case 'date':
      return !isNaN(Date.parse(value));
    case 'boolean':
      return value === 'true' || value === 'false';
    default:
      return false;
  }
};

// ...existing code...

<tbody>
  {data.map((row, rowIndex) => (
    <tr style={{ borderBottom: "1px solid #F6F6F6" }} key={rowIndex}>
      <td 
      style={{padding:'12px'}}
      className='data_create_preview_rows same-width'>Row {rowIndex + 1}</td>
      {columns.map((column, colIndex) => (
        <td className='data_create_preview_rows same-width' key={colIndex}>
          <input
            type="text"
            value={row[column] || ''}
            onChange={(e) => {
              const newData = [...data];
              const value = e.target.value;
              if (validateValue(value, dataTypes[column])) {
                newData[rowIndex][column] = value;
                setData(newData);
              } else {
                setAlertMessage(`Invalid value for ${dataTypes[column]} type`);
                setAlertType('error');
                setAlertVisible(true);
                
              }
            }}
            className='data_columns_preview_input'
          />
        </td>
      ))}
    </tr>
  ))}
</tbody>



  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className='doc_summary_cont'>
       {alertVisible && 
                  <Alert
                    title={alertMessage}
                    type={alertType}
                    onClose={() => setAlertVisible(false)}
        />}
      <div>

        <div className='doc_summary_header'>
          <h2>Customize Data</h2>
          <p>Populate and validate your data</p>
        </div>

        <div className='data_stats'>
          <p>Table Description</p>
          <ul>
            <li>Column count: {columns.length}</li>
            <li>Row count: {data.length}</li>
            <li>Empty fields: {/* Calculate empty fields */}</li>
          </ul>
        </div>

        <div className='create_data_instr'>
          <h4>How to edit your table</h4>
          <p>1. Click the “New Column” Button.</p>
          <p>2. Define the column by adding a name and a data type.</p>
          <p>3. Add as many columns as you require by repeating steps 1 & 2. </p>
          <p>4. Click the “New Row” button to add rows of data to the created columns</p>

          <div>
            <button onClick={() => setOpen(true)}>Create Data Table</button>
          </div>
        </div>
      </div>

      <div className='create-doc-btn-cont'>
        <button className='create-doc-btn-save'>Save Draft</button>
        <button onClick={() => setStep(5)} className='create-doc-btn-next'>Next Step</button>
      </div>

      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className='pdf-main-cont'>
            <div className='data-modal-prev-header'>
              <div>
                <h1>Edit Table</h1>
              </div>
              <div className='pdf-header-btn'>
              <div>
                  <h4 className='pdf-header-close-btn' onClick={()=>setOpen(false)}>Close</h4>
                </div>
                <div>
                  <h4 className='pdf-header-close-btn' onClick={handleNewRow}>New Row</h4>
                </div>
                <div>
                  <h4 className='pdf-header-close-btn' onClick={handleNewColumn}>New Column</h4>
                </div>
                <BaseButton onClick={()=>setStep(5)}  title='Submit' height='44px' width='75px' padding="0em .5em" />
              </div>
            </div>

            <section className='import-csv-previev-cont'>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ textAlign: 'left', backgroundColor: "#F6F6F6" }}>
                  <th className='preview-column-txt same-width'>Column Name</th>
                  {columns.map((column, index) => (
                    <th className='preview-column-txt same-width' key={index}>{column}</th>
                  ))}
                </tr>
                <tr style={{ textAlign: 'left' }}>
                  <th className='preview-column-txt same-width'>Column Data Type</th>
                  {columns.map((column, index) => (
                    <th key={index} className='same-width'>
                      <select
                        value={dataTypes[column]}
                        onChange={(e) => handleDataTypeChange(e, column)}
                        className='data_create_preview_select'
                      >
                        <option value="string">Single line of text</option>
                        <option value="text">Multiple lines of text</option>
                        <option value="number">Number</option>
                        <option value="date">Date</option>
                        <option value="boolean">True/False</option>
                       
                      </select>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr style={{ borderBottom: "1px solid #F6F6F6" }} key={rowIndex}>
                    <td 
                    style={{padding:'12px'}}
                    className='data_create_preview_rows same-width'>Row {rowIndex + 1}</td>
                    {columns.map((column, colIndex) => (
                      <td className='data_create_preview_rows same-width' key={colIndex}>
                        {dataTypes[column] === 'boolean' ? (
                          <select
                            value={row[column] || ''}
                            onChange={(e) => {
                              const newData = [...data];
                              newData[rowIndex][column] = e.target.value;
                              setData(newData);
                            }}
                            className='data_columns_preview_input'
                            style={{ minWidth: '160px'}}
                          >
                           
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        ) : (
                          <input
                            type={dataTypes[column] === 'number' ? 'number' : dataTypes[column] === 'date' ? 'date' : 'text'}
                            value={row[column] || ''}
                            onChange={(e) => {
                              const newData = [...data];
                              newData[rowIndex][column] = e.target.value;
                              setData(newData);
                            }}
                            className='data_columns_preview_input'
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              </table>
            </section>
          </div>
        </Box>
      </Modal>

      <Modal open={smallModalOpen} onClose={() => setSmallModalOpen(false)} aria-labelledby="small-modal-title" aria-describedby="small-modal-description">
        <Box sx={smallModalStyle}>
            <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img onClick={() => setSmallModalOpen(false)} src={closeIcon} alt="" />
                    </div>
                    <h2 className='create-clm-hdr'>Define Column</h2>
                    
             </div>

          <p className='create-clm-desc'>Describe your column. All fields are required.</p>
          
          <div className='create-clm-name'>
            <h4>Column Name</h4>
            <input 
              type="text"
              value={newColumnName} 
              placeholder='Enter column name'
              onChange={(e) => setNewColumnName(e.target.value)} />
            <p>This appears as a header at the top of the column.</p>
          </div>
          <div className='create-clm-name'>
            <h4>Column Type </h4>
              <select 
               className='create-clm-name-select'
            
              value={newColumnType} onChange={(e) => setNewColumnType(e.target.value)}>
                <option value="string">Single line of text</option>
                <option value="multiple">Multiple lines of text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="boolean">True/False</option>
               
              </select>
           
          </div>
          <div >
            <button 
            className='create-clm-btn'
            onClick={handleSaveNewColumn}>Save Column Information</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateDataTable;