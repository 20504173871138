import React,{useContext,useState} from 'react';
import './CreateDocument.scss';
import arrow from '../../../assets/backicon.svg'
import { multiStepContext } from '../Reusable/StepContext/StepContext';
import DocAcknowledge from './DocAcknowledge/DocAcknowledge';
import CreateDocSideBar from './CreateDocSidebar/CreateDocSideBar';
import DocumentSummary from './DocumentSummary/DocumentSummary'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ReportCard from '../Reusable/ReportCard/ReportCard';
import BaseButton from '../../Onboarding/Reusables/BaseButton/BaseButton';
import Alert from '../Reusable/Alert/Alert';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:626,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  
}

const CreateDocument = () => {

  
  const { currentstep,setStep } = useContext(multiStepContext);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [file, setFile] = useState(null);
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [doctype, setDocType] =  useState('') ;
  const [howDoc, setHowDoc] =  useState('');
  const [docSource, setDocSource] = useState('');
  const [docTitle,setDocTitle] = useState('');
  const [description,setDescription]=useState('');
  const [author,setAuthor]= useState('');
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLang, setSelectedLang]= useState('')
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [docId, setDocId] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
 
  const navigate = useNavigate();



  function handleDocData (docData) {
    setDocSource(docData.docSource)
    setAuthor(docData.author)
    setFile(docData.file)
    setDescription(docData.description)
    setSelectedDate(docData.selectedDate)
    setDocTitle(docData.docTitle)
    setSelectedKeyId(docData.selectedKeyId)
    setHowDoc(docData.howDoc)
    setDocType(docData.doctype)
     setSelectedLocation(docData.selectedLocation)
     setSelectedLang(docData.selectedLang)
  }

  const handleError = (error) => {

    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear(); // Clear local storage
        window.location.href = '/login'; // Redirect to login page
      } else {
        setAlertType('error');
        setAlertMessage(error.response.data.message);
        setAlertVisible(true);
      }
    } else if (error.request) {
      setAlertType('error');
      setAlertMessage("Check your connection. Unable to reach the server.");
      setAlertVisible(true);
    } else {
      setAlertType('error');
      setAlertMessage("An error occurred. Please try again later");
      setAlertVisible(true);
    }
  };

  // function dateFormat(dateStr) {
  //   // Split the input string by the delimiter "-"
  //   const [day, month, year] = dateStr.split("/");
  
  //   // Return the date in the desired format
  //   return `${year}-${month}-${day}`;
  // }
    const createDocument = () => {
       setLoading(true);
        const formData = new FormData();
        formData.append("file", file); // Assuming `file` is your uploaded PDF file
        formData.append("title", docTitle);
        formData.append("description", description);
        formData.append("author", author);
        formData.append("source", docSource);
        formData.append("access", howDoc);
        formData.append("document_type", doctype);
        formData.append("language", selectedLang); // Replace with dynamic language if needed
        selectedKeyId.forEach((item) => {formData.append('industry', item)}) 
        selectedLocation.countries.forEach((item) => {formData.append('location', item)}) 
        
        if (selectedLocation.regions.length < 5 || selectedLocation.countries.length > 1) {
          formData.append('region', 'multiple');
        } else if (selectedLocation.regions.length === 0 && selectedLocation.countries.length === 1) {
            formData.append('region', selectedLocation.countries[0]);
        } else if (selectedLocation.regions.length === 5) { // Assuming 5 represents all regions selected
            formData.append('region', 'Africa');
        } else {
            selectedLocation.regions.forEach((item) => {
                formData.append('region', item);
            });
        }
        formData.append("publication_year", selectedDate); // Ensure this is formatted correctly
  
          const config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`, // Authorization header
              },
          };
    
            axios.post(`${baseUrl}documents`, formData, config)
              .then((response) => {
                console.log("Document created successfully:", response.data);
                setLoading(false)
                setOpen(true);
                
              })
              .catch((error) => {
                setLoading(false)
                handleError(error)
              });
            
      };


      const handleUpdateChange = (value, id) => {
        setIsUpdate(value);
        setDocId(id);
      };
      const handleGoback = () => {
        if (isUpdate) {
          navigate(`/view/report/pdf/${docId}`);
        } else {
          navigate("/dashboard/contributions");
        }
      };
    

  return (
    <div>

          {alertVisible && 
                <Alert
                    title={alertMessage}
                    type={alertType}
                    onClose={() => setAlertVisible(false)}
                 />
          }

        <div className='create-doc-banner'>
           { currentstep === 1 && 
            <img 
            onClick={()=>handleGoback()}
            src={arrow} alt="" />
           }
           { currentstep === 2 && 
            <img 
            onClick={()=> setStep(1)}
            src={arrow} alt="" />
           }
        </div>

        <div className='create-doc-body'>
          {currentstep === 1 && 
          
          <DocumentSummary
          onSelect={handleDocData}
          onUpdateChange={handleUpdateChange}
          />
          
          }
          {currentstep === 2 && 
          <DocAcknowledge
           callSummaryFunction={createDocument}
           loadingFunction={loading}
          />}
         
          <CreateDocSideBar
            update={isUpdate}
          />
        </div>

        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-reject-modal' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                  
                    <h4 style={{margin:10}}>Done!</h4>
                    <p style={{textAlign:'center',color:'#4B4F57',fontSize:"1rem"}}> Your contribution has been submitted and is under review.</p>
                    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'1em' }}>
                    <ReportCard
                         contHeight={"360px"}
                         contWidth={"254px"}
                         imgBoxWidth={"100px"}
                         contPadding={"1em"}
                         transformLine={"rotate(40deg)"}
                         lineHeight={"1.6cm"}
                         lineRight={"-5px"}
                         lineTop={"0px"}
                         lineWidth={"20px"}
                         transformOrigin={"top right"}
                         headerFont={"1rem"}
                         lineBottom={"-20px"}
                         lineLeft={"25px"}
                         textMarginTop={"10px"}
                         docText={docTitle}
                         docYear={selectedDate}
                         yearFont={"0.8rem"}
                />
                    </div>
                    
                </div>

                

                <div>
                    <BaseButton
                        title={"Close"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={() =>  window.location = '/dashboard/contributions'}
                    />
                </div>
            </div>
        </div>

        </Box>
        </Modal>


    </div>
  )
}

export default CreateDocument
