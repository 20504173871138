import React,{useState,useContext,useEffect} from 'react'
import axios from 'axios';
import './DataSummary.scss';
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedIcon.svg';
import CustomDropdown from '../../Reusable/SelectDropdown/SelectDropdown';
import { multiStepContext } from '../../Reusable/StepContext/StepContext';
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { useParams } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import uploaded from '../../../../assets/uploadsucess.svg';
import bin from '../../../../assets/bin.svg';
import upload from '../../../../assets/file upload states.svg';
import CountryDropdown from '../../CreateDocument/DocumentSummary/CountryDropDown/CountryDropDown';
import { PiRadioButtonFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import Alert from '../../Reusable/Alert/Alert';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height:626,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  outline: "none",
  padding:"16px 32px 32px",
  
}

const DataSummary = ({ onSelect,onUpdateChange }) => {

  const {setStep} = useContext(multiStepContext);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const [selectedWords, setSelectedKeywords] = useState([]);
    const [selectedKeyId, setSelectedKeyId] = useState([]);
    const [industries, setIndustries]= useState([]);
    const [isWordOpen, setIsKeyword] =useState(false);
    const [isLanguageOpen, setLanguageOpen] =useState(false);
    const [howDoc, setHowDoc] =  useState('');
    const [docSource, setDocSource] = useState('');
    const [docTitle,setDocTitle] = useState('');
    const [description,setDescription]=useState('');
    const [scope,setScope]= useState('');
    const [selectedLang,setSelectedLang] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(""); // Track the selected text
    const [locationFromAPI, setLocationFromAPI] = useState([]); 
    const [open, setOpen] = useState(false); 
    const [datatype,setDataType] = useState("csv")
    const paramsVal = useParams();;
    const [csvFile, setCsvFile] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

  // Function to receive the selected text from CountryDropdown
  const handleLocation = (selectedValues) => {
    setSelectedLocation(selectedValues); // 
  
  };

    const docData = {
      selectedWords,
      docSource,
      selectedKeyId,
      howDoc,
      docTitle,
      description,
      scope,
      selectedLocation,
      selectedLang
    }

    
    
    function handleLanguage (value){
      setSelectedLang(value);
      setLanguageOpen(false)
    }

    function toggleLanguageOpen (){
        setLanguageOpen(!isLanguageOpen); 
    }
    
    function toggleTagOpen (){
        setIsKeyword(!isWordOpen)
    }
    
    

    function handleDocHow (option) {
      setHowDoc(option);
      // sessionStorage.setItem('industry',option);
    }
    
 

 
    function handleKeywordSelect (word) {

    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
        
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
    }
    
    if (selectedKeyId.includes(word.id)) {
        
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
        
    } else {
        
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
    
    };

    
    const handleError = (error) => {

      console.log("error", error);
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear(); // Clear local storage
          window.location.href = '/login'; // Redirect to login page
        } else {
          setAlertType('error');
          setAlertMessage(error.response.data.message);
          setAlertVisible(true);
        }
      } else if (error.request) {
        setAlertType('error');
        setAlertMessage("Check your connection. Unable to reach the server.");
        setAlertVisible(true);
      } else {
        setAlertType('error');
        setAlertMessage("An error occurred. Please try again later");
        setAlertVisible(true);
      }
    };
    

    const getIndustry=()=>{
 
        try {
          
          const config = {
            headers: {
              "Content-Type": "application/json",
               'Authorization': `Bearer ${token}`
            },
          };
    
          axios
            .get(`${baseUrl}industry`,config)
            .then((res) => {
           
                setIndustries(res.data.data)
            
            })
            .catch((error) => {
              handleError(error);
            });
        }
        catch (error) {
          console.log("error", error);
        }
    }



      const sourceOptions = [
        'Social Media',
        'Company Report',
        'I work there',
        'I would rather not  say',
      ]

      function goToConfirmPage(e) {
        e.preventDefault()
        const docId = paramsVal.docid;
      
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header
          },
        };
      
        if (docId) {

          let body = {
            title: docTitle,
            description : description,
            source: docSource,
            access: howDoc,
            language: selectedLang,
            industry: selectedKeyId,
            location: selectedLocation.countries,
            region: selectedLocation.regions.length === 5 ? 'Africa' : selectedLocation.regions.length < 5 || selectedLocation.countries.length > 1 ? 'multiple' : selectedLocation.regions.length === 0 && selectedLocation.countries.length === 1 ? selectedLocation.countries[0] : selectedLocation.regions
          };
  
          if (
            !selectedKeyId.length === 0|| 
            !scope || 
            !selectedLocation|| 
            !howDoc ||
            !selectedLang ||
            !docSource || 
            !docTitle || 
            !description 
          ) {
            setAlertMessage('Please fill in all the fields before proceeding');
            setAlertType('error');
            setAlertVisible(true);
          
            return; 
          }
          axios.patch(`${baseUrl}documents/contributor/${docId}`, body, config)
            .then((response) => {
              console.log(response)
              setOpen(true);
            
            })
            .catch((error) => {
             handleError(error)
            });
        } else {
          if (
            (datatype === "csv" &&
              (!selectedKeyId.length || 
              !scope ||  
              !selectedLang ||
              !docSource || 
              !docTitle || 
              !selectedLocation ||
              !description ||
              !csvFile)) ||
            (datatype === "manual" &&
              (!selectedKeyId.length || 
              !scope ||  
              !selectedLang ||
              !docSource || 
              !docTitle || 
              !selectedLocation ||
              !description))
          ) {
            setAlertMessage('Please fill in all the fields before proceeding');
            setAlertType('error');
            setAlertVisible(true);
          
            return; 
          }
          
          onSelect(docData);
          if (datatype === "csv") {
            navigate('#', { state: { csvFile, docData } });
            setStep(2);
          } else if (datatype === "manual") {
            setStep(3);
          }
         
          window.scrollTo(0, 0);
        }
      }



      const getDocuments = () => {
     
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
  
            axios.get(`${baseUrl}documents/contributor/${paramsVal.docid}`, config)
                .then((response) => {
                    const data = response.data.data;
                    setDocTitle(data.title);
                    setDescription(data.description);
                 
                    setDocSource(data.source);
                  
                    setSelectedLang(data.language);
                  
                    setLocationFromAPI(data.location || [])
                   
                    setHowDoc(data.access);
                    setSelectedKeywords(data.industries.map(industry => industry.name));
                    setSelectedKeyId(data.industries.map(industry => industry.id));
                   
                })
                .catch((error) => {
                 
                    if (error.response) {
                        console.error('Error fetching document:', error.response.data);
                    } else {
                        console.error('Error fetching document:', error.message);
                    }
                });
        } catch (error) {
           
            console.error('Error fetching document:', error.message);
        }
    };


    
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      handleFileUpload(selectedFile);
  };

    const handleFileUpload = (file) => {
        if (file && file.type === 'text/csv') {
          if (file.size <= 10 * 1024 * 1024) { // 10MB in bytes
            setCsvFile(file);
            setError('');
          } else {
            setError('File size exceeds 10MB');
          }
        } else {
          setError('Please upload a valid CSV file');
        }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      const droppedFile = e.dataTransfer.files[0];
      handleFileUpload(droppedFile);
    };

  
    useEffect(() => {
      if (paramsVal.docid) {
          getDocuments();
          const isUpdate = paramsVal.docid !== undefined;
          onUpdateChange(isUpdate, paramsVal.docid);
      }
       // eslint-disable-next-line
    }, [paramsVal.docid,onUpdateChange]);

      useEffect(() => {
         getIndustry()
         // eslint-disable-next-line
      },[]);

  return (
    <div className='doc_summary_cont'>

          {alertVisible && 
            <Alert
              title={alertMessage}
              type={alertType}
              onClose={() => setAlertVisible(false)}
            />}

      <div className='doc_summary_header'>
        <h2>Data Summary</h2>
        <p>Fill out these details to describe your data.</p>
      </div>

      <form action="">
        
        <div>
            <h4>Title <span style={{color:'red'}}>*</span></h4>
            <input 
            className='doc_summary_cont_title'
            placeholder='Enter title here'
            type="text"
            value={docTitle}
            onChange={(e)=>setDocTitle(e.target.value)}
            />
        </div>
        
        <div>
           <h4>Description<span style={{color:'red'}}>*</span></h4>
           <textarea 
            placeholder='Enter text here'
            name="" 
            id=""
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
           >

           </textarea>

           <p className='doc_summary_helper'> Keep this below 150 words</p>
        </div>

     
        <div style={{position:'relative'}}>
                    <h4>Tags<span style={{color:'red'}}>*</span></h4>
                    
                    <div className='tags_drop' onClick={()=>toggleTagOpen()}>
                        {selectedWords.length > 0 ? <h4> {selectedWords.slice(0,12) + (selectedWords.length > 12 ? "   (+" + (selectedWords.length - 12) + ")" : "")}</h4>: <p>Select Tags</p>}

                        {isWordOpen ? 
                        <IoIosArrowUp
                        color='#9CA0A5'
                        /> :
                        <IoIosArrowDown
                        color='#9CA0A5'
                        
                        />}
                             
                       
                    </div>

                    {isWordOpen && 
                      <div className='create_filter_keywd_main'>
                          <div className='create_filter_keywd'>
                              {industries.map((item,index)=>(
                                  <div className='create_filter_keywd_grid' key={index}>
                                      <div>
                                          {selectedWords.includes(item.name) ? (
                                              
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkedIcon} alt="check" />
                                          ):(
                                              <img 
                                              onClick={()=>handleKeywordSelect(item)}
                                              src={checkIcon} alt="check" />
                                          )}
                                          

                                      </div>
                                      <h5>{item.name}</h5>
                                  </div>                            
                              
                              ))}

                              
                          
                          </div>
                              <div style={{marginTop:'0px'}} className='create_filter_btn'>
                                  <button 
                                  disabled={selectedWords.length === 0}
                                  onClick={()=>setIsKeyword(false)}
                                  >
                                      Select
                                  </button>
                              </div>
                              
                      </div>
                    }
      
        </div>

        <div>
            <h4>Scope <span style={{color:'red'}}>*</span></h4>
            <input 
            className='doc_summary_cont_title'
            placeholder='Enter data scope'
            type="text"
            value={scope}
            onChange={(e)=>setScope(e.target.value)}
            />
        </div>

        
        <div className='doc-summary-side'>
          
            <div className='dropdown_main'>
                <h4>Language <span style={{color:'red'}}>*</span></h4>

                <div className='loc_drop' onClick={()=>toggleLanguageOpen()}>
                  {selectedLang ?  <h4>{selectedLang} </h4>:
                  
                  <p>Select the appropriate language</p>}

                  {isLanguageOpen ? 
                  <IoIosArrowUp
                  color='#9CA0A5'
                  /> :
                  <IoIosArrowDown
                  color='#9CA0A5'
                  
                  />}
                  
                
                </div>
                
                  {isLanguageOpen && 
                        <div className='create_doc_filter_lang'>
                            <div onClick={()=>handleLanguage("English")}>
                                {selectedLang ==="English" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>English</h3>
                            </div>

                            <div onClick={()=>handleLanguage("French")}>
                            {selectedLang ==="French" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>French</h3>
                            </div>

                            <div onClick={()=>handleLanguage("Portuguese")}>
                            {selectedLang ==="Portuguese" ? 
                                <IoMdRadioButtonOn 
                                color='#007EFF'
                                />
                                :
                                <IoIosRadioButtonOff
                            
                                color='#DDD8D0'
                                /> }
                                <h3>Portuguese</h3>
                            </div>

                        </div>
                  }
            </div>

            <div>
                <h4>Geographical Coverage<span style={{color:'red'}}>*</span></h4>
                <CountryDropdown
                  placeholder={"Select location this data covers"}
                  heightFor="42px"
                  paddingFor="0.5em"
                  marginFor={"10px"}
                  selectedCountry={handleLocation}
                  locationFromAPI={locationFromAPI} // Pass API locations to dropdown
                />
            </div>
        </div>

        <div className='doc-summary-side'>
            <div>
                    <h4>Source<span style={{color:'red'}}>*</span></h4>
                    <input 
                     className='doc_summary_cont_source'
                    placeholder="Enter data’s source"
                    type="text"
                    value={docSource}
                    onChange={(e)=>setDocSource(e.target.value)}
                    />
            </div>

            <div>
                <h4>How did you get this data? </h4>

                <div>
                  <CustomDropdown
                    options={sourceOptions}
                    selectedOption={howDoc}
                    placeholder={"How did you get this data?"}
                     heightFor="42px"
                    paddingFor="0.5em"
                    onSelect={handleDocHow}
                    marginFor={"10px"}
                    
                  />
                </div>
            </div>
        </div>
          

        <div>
            <p style={{marginBottom:'4px'}}>How would you like to create your contribution? <span style={{color:'red'}}>*</span> </p>
            <div className='data_create_opt'>
                <div className='data_create_one'>
                {datatype === "csv" ?
                    <PiRadioButtonFill
                        color='#007EFF'
                        size={20}
                        
                    />
                    :
                    <IoIosRadioButtonOff
                    color='#DDD8D0'
                    size={20}
                    onClick={()=>setDataType("csv")}
                    />
                }
                    <div >
                        <h4>Import CSV File</h4>
                        <p>Upload a CSV file containing data</p>
                    </div>
                </div>

                <div className='data_create_one'>
                    {datatype === "manual" ?
                    <PiRadioButtonFill
                        color='#007EFF'
                        size={20}
                    />
                    :
                    <IoIosRadioButtonOff
                    color='#DDD8D0'
                    size={20}
                    onClick={()=>setDataType("manual")}
                    />
                    }
                   <div>
                        <h4>Create Table Manually</h4>
                        <p>Build a table from scratch</p>
                    </div>
                </div>
            </div>
        </div>

        {datatype === "csv" &&
        <div>
            <h4>Attach the CSV file containing your report.<span style={{color:'red'}}>*</span></h4>

                   {csvFile ? (
                        <>
                             <div className="more_about_one_pp">
                                <div>
                        
                                    <div>
                                        <img src={uploaded} alt="Upload Icon" />
                                    </div>

                                    <p className="click_drag_uploaded">
                                     Upload Successful
                                    </p>
                                                                   
                                    {csvFile && <p className="click_svg">{csvFile.name}</p>}
                                    <div 
                                     onClick={()=>setCsvFile(null)}
                                    className='clear_upload'>
                                    <img 
                                   
                                    src={bin} alt="" />
                                    <h4 style={{margin:'0'}}>Clear upload</h4>
                                </div>
                                </div>
         
                            </div>
                            
                        </>
                           
                        ):(
                        <>
            
                <div 
                    className="more_about_one_pp"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    <div>
                    
                        <div>
                            <img src={upload} alt="Upload Icon" />
                            </div>

                                <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                accept=".csv"
                                onChange={handleFileChange}
                                />

                                <p className="click_drag_upload">
                                Click to upload <span>or drag and drop</span>
                                </p>
                                <p className="click_svg">CSV (max. 10 megabytes)</p>

                                {error && <p className="error-message">{error}</p>}
                              
                        </div>
                    </div>
                    </>)}
                    
        </div>}


        <div className='create-doc-btn-cont'>

          <button className='create-doc-btn-save'>
           Save Draft
          </button>

          <button 
          onClick={(e)=>goToConfirmPage(e)}
          className='create-doc-btn-next'>
            Next Step
          </button>

        </div>
       

     </form>

     <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >


        </Box>
        </Modal>


    </div>
  )
}

export default DataSummary

