import React from "react";
import "./Alert.scss";
import errorImg from "../../../../assets/error.svg";
import infoImg from "../../../../assets/info.svg";
import successImg from "../../../../assets/success.svg";
import warningImg from "../../../../assets/warning.svg";
import { IoClose } from "react-icons/io5";

const Alert = ({ title, description, onClose, type }) => {

const icons = {
    error: errorImg,
    info: infoImg,
    success: successImg,
    warning: warningImg,
    };
   
    const iconSrc = icons[type] || infoImg; // Default to infoImg if type is not found

  return (
    <div className="alert-container">
    <div className={`alert alert-${type}`}>
      <img className="alert-icon" src={iconSrc} alt={type} />
      <div className="alert-content">
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
      <IoClose
      className="alert-close" onClick={onClose}

     />
    </div>
    </div>
  );
};

export default Alert;